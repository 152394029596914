(function($, root, undefined) {
	$(function() {
		"use strict";

		$("[data-fancybox]").fancybox({
			animationEffect: "fade"
		});

		$(".hamburger").click(function() {
			$(this).toggleClass("is-active");
			$(".zenon-logo").fadeToggle(300);
			$("#mobile-menu").toggleClass("show");
			$("body").toggleClass("disableScroll");
			$("#banner, footer, #content").toggleClass("blurContent");
		});

		// Google analytics
		function googleAnalytics() {
			var AnalyticsScript = document.createElement('script');
			var AnalyticsId = 'GTM-N38K9N5S';
			AnalyticsScript.onload = function () {
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }
				gtag('js', new Date());
				gtag('config', AnalyticsId);
			};
		
			AnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + AnalyticsId;
			document.head.appendChild(AnalyticsScript);
		}
		
		// if (getCookie('cookieControlTracking') && getCookie('cookieControlTracking') == 'accept') {
		// 	googleAnalytics();
		// }
		
		document.addEventListener('cookieAccept', function () {
			googleAnalytics();
		}, false);

		$(function() {
			var istop = $("body");
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				if (scroll >= 40) {
					istop.removeClass("top").addClass("not-top");
					$("#header-top").addClass("scrolling");
				} else {
					istop.removeClass("not-top").addClass("top");
					$("#header-top").removeClass("scrolling");
				}
			});
		});

		$(".header-login-button")
			.find("a")
			.attr({ "data-toggle": "modal", "data-target": "#loginModal" });

		// $('.owl-carousel').owlCarousel({
		// 	loop:true,
		// 	items:1,
		// 	autoplay:true,
		// 	autoplaySpeed: 2000,
		// 	autoplayTimeout:8000,
		// 	dotsData: true
		// });

		$(".banner-carousel").owlCarousel({
			loop: true,
			items: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			autoplayTimeout: 8000,
			dotsData: true,
			animateOut: "fadeOut"
		});

		$(".footer-logo-carousel").owlCarousel({
			loop: true,
			items: 4,
			autoplay: true,
			autoplaySpeed: 2000,
			autoplayTimeout: 8000,
			responsive: {
				0: {
					items: 2
				},
				768: {
					items: 4
				}
			}
		});

		$(".homepage-news-carousel").owlCarousel({
			loop: false,
			dots: true,
			items: 3,
			slideBy: 3,
			autoplay: false,
			center: false,
			margin: 20,
			responsiveRefreshRate: 200,
			responsive: {
				0: {
					items: 1,
					center: true
				},
				768: {
					items: 2,
					loop: false
				},
				992: {
					items: 3,
					loop: false,
					dots: false,
					mouseDrag: false,
					touchDrag: false
				}
			}
		});

		$(".owl-dot").click(function() {
			$(".banner-carousel").trigger("to.owl.carousel", [
				$(this).index(),
				300
			]);
		});

		$(".j-lazy-image").each(function() {
			var imgSrcUrl = $(this).data("src");
			var imgSrcSetUrl = $(this).data("srcset");
			$(this).waypoint(
				function() {
					if ($(this.element).attr("data-srcset")) {
						$(this).load(imgSrcSetUrl, function() {
							$(this.element)
								.attr("srcset", imgSrcSetUrl)
								.addClass("loaded");
						});
					}
					if ($(this.element).data("src")) {
						$(this).load(imgSrcUrl, function() {
							$(this.element)
								.attr("src", imgSrcUrl)
								.addClass("loaded");
						});
					}
				},
				{ offset: "90%" }
			);
		});

		$(".j-lazy-bg").each(function() {
			var imgSrcUrl = $(this).data("src");
			var thumbImgSrcUrl = $(this).css("background-image");
			$(this).waypoint(
				function() {
					$(this).load(imgSrcUrl, function() {
						$(this.element)
							.css({
								"background-image":
									"url(" + imgSrcUrl + "), " + thumbImgSrcUrl
							})
							.addClass("loaded");
					});
				},
				{ offset: "90%" }
			);
		});
	});
})(jQuery, this);
